/* Print styling */

[class*="col-sm-"] {
    float: left;
}

[class*="col-xs-"] {
    float: left;
}

.col-sm-12,
.col-xs-12 {
    width: 100% !important;
}

.col-sm-11,
.col-xs-11 {
    width: 91.66666667% !important;
}

.col-sm-10,
.col-xs-10 {
    width: 83.33333333% !important;
}

.col-sm-9,
.col-xs-9 {
    width: 75% !important;
}

.col-sm-8,
.col-xs-8 {
    width: 66.66666667% !important;
}

.col-sm-7,
.col-xs-7 {
    width: 58.33333333% !important;
}

.col-sm-6,
.col-xs-6 {
    width: 50% !important;
}

.col-sm-5,
.col-xs-5 {
    width: 41.66666667% !important;
}

.col-sm-4,
.col-xs-4 {
    width: 33.33333333% !important;
}

.col-sm-3,
.col-xs-3 {
    width: 25% !important;
}

.col-sm-2,
.col-xs-2 {
    width: 16.66666667% !important;
}

.col-sm-1,
.col-xs-1 {
    width: 8.33333333% !important;
}

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    float: left !important;
}

body {
    margin: 0;
    padding: 0 !important;
    min-width: 768px;
    font-size: 18px;
}

img {
    max-width: 100%;
}

.image_left.image-div {
    width: 50%;
}

.more-articles-section {
    display: none;
}

.tags {
    display: none;
}

.container {
    width: auto;
    min-width: 750px;
}

a[href]:after {
    content: none;
}

.navbar-nav {
    display: none !important;
}

.navbar-brand {
    display: block !important;
    margin-top: 0px;
    width: 200px;
}

footer img {
    display: none;
}

.acs-logo-print {
    position: absolute;
    top: 0;
    right: 0;
    height: 72px;
    width: 200px;
}

.meta {
    margin-bottom: 15px;
}
.tags {
    &:before {
        content: "Tags:";
        margin-right: 10px;
    }
    > a {
        margin-right: 15px;
    }
}

.more-article .flex-container {
    padding: 0;
}
.more-articles .card-item {
    width: 22%;
    padding: 0 1% 0 0;
    display: inline-block;
}
.authorContent {
    .image-div,
    .text {
        display: inline-block;
    }
}

.noprint,
.header > .hat,
div.alert,
.btn,
button,
.sharebar2,
#back-to-top,
.social,
.sitelinks,
#search-icon,
#search-full,
.tooltip-inner,
form,
ul.links.list-inline,
ul.action-links,
.sr-only-focusable,
.main-article-content > .category,
.subscribe-wrapper {
    display: none !important;
}
